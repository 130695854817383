import React from 'react'
import Img from 'gatsby-image'
import PageHeading from '../Page/PageHeading'

const CoachingPanel = ({ heading, image, body, cta, bg, last }) => {
  return (
    <div className={`relative w-full md:w-1/2 bg-${bg}`}>
      <PageHeading heading={heading} bg="white" />
      <div className="relative px-ogs pt-10 lg:pt-15 pb-10 md:pb-48 z-10">
        {image.localFile && (
          <div className="mb-10 lg:mb-14">
            <Img
              fluid={image.localFile.childImageSharp.fluid}
              alt={image.alt}
            />
          </div>
        )}
        <div className="flex flex-wrap -ml-ogs">
          {body && (
            <div
              className="lg:w-1/2 pl-ogs mb-4 lg:mb-0 text-sm lg:text-xl leading-tight rte"
              dangerouslySetInnerHTML={{
                __html: body.html,
              }}
            />
          )}
          {cta && (
            <div
              className="lg:w-1/2 pl-ogs text-sm lg:text-xl leading-tight rte"
              dangerouslySetInnerHTML={{
                __html: cta.html,
              }}
            />
          )}
        </div>
      </div>
      {!last && <div className="w-full h-hh bg-white md:hidden" />}
    </div>
  )
}

const CoachingPanels = ({ page }) => {
  return (
    <div className="flex flex-wrap">
      <CoachingPanel
        heading={page.heading_primary}
        image={page.image_primary}
        body={page.body_primary}
        cta={page.cta_primary}
        bg="rose"
      />
      <CoachingPanel
        heading={page.heading_secondary}
        image={page.image_secondary}
        body={page.body_secondary}
        cta={page.cta_secondary}
        bg="grey"
        last
      />
    </div>
  )
}

export default CoachingPanels
