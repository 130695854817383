import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageLead from '../components/Page/PageLead'
import PageHero from '../components/Page/PageHero'
import PageStickyBar from '../components/Page/PageStickyBar'
import CoachingPanels from '../components/Coaching/CoachingPanels'

const CoachingPage = ({ location, data }) => {
  const page = data.prismicCoaching.data
  return (
    <Layout title={page.title} headerColor="peach" quoteColor="peach">
      <SEO
        title={page.meta_title || page.title}
        description={page.meta_description || ''}
        location={location}
      />
      <PageLead lead={page.lead.html} color="grey" />
      <PageHero image={page.hero} color="green" alt />
      <div className="relative pt-hh">
        <PageStickyBar
          headings={[page.heading_primary, page.heading_secondary]}
        />
        <div className="relative z-10">
          <CoachingPanels page={page} />
        </div>
      </div>
    </Layout>
  )
}

CoachingPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default CoachingPage

export const pageQuery = graphql`
  query {
    prismicCoaching {
      data {
        title
        body_primary {
          html
        }
        body_secondary {
          html
        }
        cta_primary {
          html
        }
        cta_secondary {
          html
        }
        heading_primary
        heading_secondary
        hero {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_primary {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_secondary {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        lead {
          html
        }
        meta_description
        meta_title
      }
    }
  }
`
